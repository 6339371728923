import {Route} from '../helpers/Route';

export enum SearchKey {
  type = 'type',
  from = '',
  to = '',
  link = 'link',
}

export const route = {
  login: Route.of({path: '/login'}),
  magicLink: Route.of({path: '/magic-link-access'}),
  magicLinkSendgrid: Route.of({path: '/sendgrid-magic-link'}),
  auth: Route.of({path: '/auth'}),
  forgot: Route.of({path: '/forgot-password'}),
  resetPassword: Route.of({path: '/reset-password'}),

  // authorized routes
  emailUpload: Route.of({path: '/email-upload'}),
  groups: Route.of({path: '/'}),
  groupView: Route.of<{id: string}>({path: '/group/:id'}),
  welcomeScreen: Route.of({path: '/welcome'}),
};

export const routePaths = {
  groupView: '/group/',
};
