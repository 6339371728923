import React from 'react';
import {PageWrapper} from '../../components/Layout/Page';
import {PageHeader} from '../../components/Common/PageHeader';
import {useGetCurrentGroup} from '../../hooks/state';
import {PageLoader} from '../../components/Common/PageLoader';
import {EmptyGroupState} from '../../components/Common/EmptyState/EmptyGroupState';
import {getSendLink} from '../../constants/routes';
import {GroupInfoExpander} from '../../components/Common/GroupInfoExpander';
import {GuestList} from './list';
import {useCurrentGroupFromUrlParam} from '../../hooks/state';

export const GuestsPage = () => {
  useCurrentGroupFromUrlParam();
  const {currentGroup} = useGetCurrentGroup();
  const group = currentGroup.group;

  if (!group && currentGroup?.loading) return <PageLoader />;
  if (!group) return <EmptyGroupState />;

  return (
    <PageWrapper scrollFixed>
      <PageHeader
        title="Guest List"
        subtitle="View your sent tickets below."
        linkText="Send Codes"
        link={getSendLink(group)}
      />
      <GroupInfoExpander group={group} />
      <GuestList groupId={group.id} />
    </PageWrapper>
  );
};
