import {useRecoilState, useRecoilValue} from 'recoil';
import {currentGroupState, groupsState} from '../states/groups';

export const useChangeGroup = () => {
  const [{group}, setCurrentGroup] = useRecoilState(currentGroupState);
  const groups = useRecoilValue(groupsState);

  const onChange = (id: string | number) => {
    const selected = groups?.groups?.find((el) => el?.id === id);
    if (selected) setCurrentGroup((prev) => ({...prev, group: selected}));
  };
  return {onChange, currentGroup: group};
};
