export const emailRegexp =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const validateEmail = (email?: string) => {
  return emailRegexp.test(String(email).toLowerCase());
};

export const validatePassword = /(?=.*[a-z])([^\s]){8,16}$/;
// export const validatePassword = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[^\w\d\s:])([^\s]){8,16}$/;

export const MAX_CODES_PER_GUEST = 10;

export const getErrorResponse = (e: unknown) => {
  try {
    const jsonErr = JSON.parse(JSON.stringify(e));
    return jsonErr?.response?.text && JSON.parse(jsonErr?.response?.text);
  } catch (e) {
    return false;
  }
};

export const getDifferenceInDays = (d1: Date, d2: Date) => {
  return (d2.getTime() - d1.getTime()) / (1000 * 3600 * 24);
};

export const IsMobileVersion = window.innerWidth < 1024;
