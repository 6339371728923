import styled from 'styled-components';
import {Typography, Form} from 'antd';
import {Button} from '../../ui-kit/Button';
import Input, {FormItem} from '../../ui-kit/Input';
import goldCorner from '../Auth/assets/goldcorner.png';
import trees from '../../ui-kit/icons/treesgold.svg';
import {Footer} from '../../ui-kit/AppLayout/Footer';
import {fontSecondaryLight} from '../../ui-kit/theme/font';
import {ArrowLeftOutlined} from '@ant-design/icons';
const {Title, Text} = Typography;
import {Media} from '../../ui-kit/theme/breakpoints';

export const PageWrapper = styled.div`
  width: 100%;
  background-color: ${({theme}) => theme.palette.background.white};
  display: flex;
  box-sizing: border-box;
  flex-direction: row;
  min-height: 100vh;
  font-family: Proxima Nova;

  ${Media.down.l} {
    flex-direction: column;
    min-height: unset;
    height: 100%;
  }
`;

export const LogoSectionWrapper = styled.div`
  background-color: ${({theme}) => theme.palette.background.midnightBlue};
  background-image: url(${goldCorner}), url(${trees});
  background-position: left top, right bottom;
  background-repeat: no-repeat, no-repeat;
  width: 100%;
  min-height: 788px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  ${Media.down.l} {
    display: none;
  }
`;

export const Logo = styled.img`
  width: 273px;
  height: 56px;
  margin: 0 auto;
  margin-top: 50px;
  display: block;
`;

export const RightPart = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  & footer {
    position: absolute;
    bottom: 0;
    left: 0;
  }
  ${Media.down.l} {
    padding: 10px 15px 20px 15px;
    height: 100%;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

export const LoginFormWrapper = styled(Form)`
  width: 100%;
  max-width: 365px;
`;

export const LoginFormTitle = styled(Title).attrs({
  level: 3,
})`
  padding-bottom: 25px;
`;

export const LogoSectionTitle = styled(Title).attrs({
  level: 3,
})`
  &.ant-typography {
    color: ${({theme}) => theme.palette.background.lightGold};
    margin: 0;
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 32px;
    ${fontSecondaryLight};
  }
`;

export const StyledAuthInput = styled(Input)`
  &:hover {
    border: 2px solid ${({theme}) => theme.palette.common.brown};
  }
  &.ant-input.ant-input-status-error:not(.ant-input-disabled):not(.ant-input-borderless) {
    border: 2px solid ${({theme}) => theme.palette.common.redBrown};
    box-shadow: none;
  }
  &.ant-input.ant-input-status-success.ant-input:focus {
    box-shadow: none;
    border: 1px solid ${({theme}) => theme.palette.border.darkGray};
  }
`;

export const StyledAuthFormItem = styled(FormItem)`
  .ant-form-item-explain-error {
    color: ${({theme}) => theme.palette.common.redBrown};
    font-size: 10px;
    margin-top: 8px;
  }
  .ant-form-item-required {
    width: 100%;
  }
  .ant-col.ant-form-item-label {
    padding-bottom: 4px;
  }
`;

export const PasswordLabelWrapper = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

export const ForgotFormWrapper = styled.div`
  a {
    font-size: 10px;
    color: ${({theme}) => theme.palette.common.redBrown};
    line-height: 12px;
    font-weight: 700;
    padding-top: 5px;
  }
`;

export const SubmitBtn = styled(Button).attrs({
  type: 'primary',
})`
  width: auto;
  margin-top: 10px;
  height: 40px;
  ${Media.down.l} {
    display: block;
    margin: 0 auto;
  }
`;

export const FooterWrapper = styled(Footer)`
  height: 20px;
  padding-bottom: 0;
  background-color: transparent;
  border: none;
  margin-bottom: 16px;
`;

export const EmailResultLine = styled.div<{$isError?: boolean}>`
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${({$isError, theme}) =>
    $isError ? theme.palette.common.redBrown : theme.palette.common.darkGreen};
  border-radius: 3px;
`;

export const ResultText = styled(Text)`
  font-size: 14px;
  color: ${({theme}) => theme.palette.common.white};
`;

export const ErrorText = styled(Text)`
  font-size: 12px;
  color: ${({theme}) => theme.palette.common.redBrown};
  position: absolute;
`;

export const ChosePortalWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const GoPortalBtn = styled(Button)`
  margin-left: 10px;
`;

export const GoBack = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  &:hover {
    span {
      text-decoration: underline;
    }
  }
`;

export const ArrowBackIcon = styled(ArrowLeftOutlined)`
  margin-right: 10px;
  color: ${({theme}) => theme.palette.text.primary};
`;
