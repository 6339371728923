import React, {useEffect, useState} from 'react';
import {EmailUploadPage} from '../../components/EmailUpload';
import {useSendNotSentEmails, useUploadEmails} from '../../hooks/emails';
import {useGetGroups} from '../../hooks/groups';
import {createGroupsOptions} from '../../helpers/codes';

export const EmailUpload = () => null;
export const EmailUploadOld = () => {
  const [groupIds, setGroupIds] = useState<string[]>([]);

  const {groups} = useGetGroups();
  const formData = useUploadEmails(groupIds);
  const sendData = useSendNotSentEmails(groupIds);
  const groupsOptions = createGroupsOptions(groups);

  const handleSetGroups = (id: string) => {
    const ids = id.includes('&') ? id.split('&') : [id];
    setGroupIds(ids);
  };
  useEffect(() => {
    setGroupIds(groups.map((el) => String(el.id)));
  }, [groups?.length]);

  return (
    <EmailUploadPage
      formData={formData}
      sendData={sendData}
      groupsOptions={groupsOptions}
      handleSetGroups={handleSetGroups}
      groupIds={groupIds}
    />
  );
};
