import {linkPortal} from '../queries/types/auth';

export interface User {
  id: string;
  email?: string;
  active?: boolean;
  departmentId?: number;
  firstName?: string;
  lastName?: string;
  phoneNumber?: string;
  roleId?: number;
  userType?: string;
}

export interface Session {
  sessionToken: string;
  user: User;
}

export enum MagicLinkFields {
  email = 'email',
  magicCode = 'magicCode',
  portal = 'portal',
}

export type MagicLinkBodyT = {
  [MagicLinkFields.email]?: string;
  [MagicLinkFields.magicCode]?: string;
  [MagicLinkFields.portal]?: linkPortal;
};
