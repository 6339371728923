import {
  CodeGroupGuestT,
  codeItemT,
  CodeSendStatuses,
  CodeStatusTypes,
  GroupDetailTableDataT,
  UsedCodesT,
} from '../types/codes';
import {SelectOptionT} from '../types/helpers';
import {CodeGroupT, getListPromoCodesRes, PromoCodeT} from '../queries/types/groups';
import {emailState} from '../components/Groups/View/Table';

export const getTableCodesFilterOptions = (): SelectOptionT[] => {
  return [
    {label: 'Show All', value: 'all'},
    {label: 'Code sent', value: CodeStatusTypes.sent},
    {label: 'Code used', value: CodeStatusTypes.used},
  ];
};
export const createGroupsOptions = (groups: CodeGroupT[]): SelectOptionT[] => {
  const options = groups.map((el) => ({
    label: `group ${el?.id}: ${el?.clientName}, ${el?.ticketType}, ${el?.departmentId}`,
    value: String(el?.id),
  }));
  options.push({label: 'All groups', value: groups.map((el) => el.id).join('&')});
  return options;
};

export const promoCodesToTableState = (values: getListPromoCodesRes): {table: GroupDetailTableDataT[]; used: any} => {
  const table = values.map((el) => ({
    key: el.id,
    id: String(el.id),
    code: {code: el.code, addRow: false, id: String(el.id)},
    guestName: {guestName: el.guestName, addRow: false, id: String(el.id), email: el?.email},
    email: {email: el?.email, edit: true, id: String(el.id)},
    actions: {edit: false, email: el?.email, codeStatus: el.outboundStatus as CodeSendStatuses, id: String(el.id)},
  }));
  return {table, used: undefined};
};

export const validateEmail = (email?: string) => {
  return String(email)
    .toLowerCase()
    .match(
      /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
    );
};

export const emailStateToArray = (emails: emailState) => {
  const rows: {id?: string; email?: string; name?: string}[] = Object.keys(emails).map((el) => ({
    id: el,
    email: emails?.[el].email,
    name: emails?.[el]?.name,
  }));
  return rows.filter((el) => validateEmail(el.email));
};
const getStatusFromCodes = (codes?: PromoCodeT[], codesIds?: number[]) => {
  if (!codesIds?.length) return CodeSendStatuses.notsent;
  let status = CodeSendStatuses.sent;
  codesIds?.forEach((id) => {
    if (codes?.find((code) => String(code?.id) === String(id))?.outboundStatus === CodeSendStatuses.notsent)
      status = CodeSendStatuses.notsent;
  });
  return status;
};

export const groupByGuestTableState = (
  groupGuests: CodeGroupGuestT[],
  codes?: PromoCodeT[],
): {table: GroupDetailTableDataT[]; used: UsedCodesT} => {
  const unusedCodes: codeItemT[] = codes?.filter((el) => !el?.email)?.map((el) => ({id: el?.id, code: el?.code})) || [];
  const withEmails = groupGuests
    ?.filter((el) => el?.guestEmail)
    ?.sort((a, b) => +new Date(b.updatedAt) - +new Date(a.updatedAt));

  const addRowIndex = -1;
  const addRow = {
    key: addRowIndex,
    id: String(addRowIndex),
    guestName: {addRow: true, id: String(addRowIndex)},
    email: {addRow: true, id: String(addRowIndex)},
    code: {
      addRow: true,
      id: String(addRowIndex),
      unused: unusedCodes,
    },
    actions: {addRow: true, id: String(addRowIndex), unused: unusedCodes},
  };

  const tableData = withEmails.map((el) => {
    const id = String(el?.id);
    const used = el?.promoCodeIds?.map((el) => {
      const codeValue = codes?.find((code) => el === code?.id);
      return {id: codeValue?.id, code: codeValue?.code, status: codeValue?.outboundStatus};
    });
    return {
      key: el?.id,
      id: String(id),
      guestName: {guestName: el.guestName, addRow: false, id: id, email: el?.guestEmail},
      email: {email: el?.guestEmail, edit: true, id: id},
      code: {
        id: id,
        unused: unusedCodes,
        used,
      },
      actions: {
        edit: false,
        email: el?.guestEmail,
        codeStatus: getStatusFromCodes(codes, el?.promoCodeIds),
        id: id,
        unused: unusedCodes,
        used,
      },
    };
  });
  const used = tableData.map((el) => ({
    id: el?.id,
    used: el?.code.used,
  }));
  return {table: [addRow, ...tableData], used};
};

// export const MAX_CODES_PER_GUEST = 100;
