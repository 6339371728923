import {useRecoilState, useSetRecoilState} from 'recoil';
import {useEffect, useState} from 'react';
import {session as sessionState, userState} from '../states/session';
import Cookies from 'js-cookie';
import {
  authorizeQuery,
  authorizeRes,
  loginQuery,
  logoutQuery,
  RequestPasswordWithCode,
  ResetPassword,
  SendResetPasswordEmail,
  UpdatePassword,
  UpdateUser,
  userQuery,
} from '../queries/auth';
import {useNavigate, useSearchParams} from 'react-router-dom';
import {searchKeys} from '../types/helpers';
import {toResetByEmailData, toResetPasswordData, toResetWithCodeData, toUpdatePasswordData} from '../helpers/auth';
import {route} from '../constants/routes';
import {session} from '../states/session';
import {useLoggedModals} from './onboarding';

type TokenType = string | null | undefined;
// let token: TokenType;
export const getToken = (): TokenType => {
  return Cookies.get('id_token');
};

export const setToken = (token: TokenType): TokenType | void => {
  if (!token) {
    return Cookies.remove('id_token');
  }
  return Cookies.set('id_token', token, {expires: 365});
};

export function useFetchSession() {
  const [loading, setLoading] = useState(true);
  const [success, SetSuccess] = useState(false);
  const checkAuth = useCheckAuthorize();
  const setSession = useSetRecoilState(sessionState);
  const [mounted, setMounted] = useState(false);
  const token = getToken();
  const {updateLS} = useUpdateLastSession();

  useEffect(() => {
    if (mounted) return;
    setMounted(true);
    const setAuthorize = async () => {
      if (!token) return;

      //check exist token
      const res = await checkAuth();
      if (res) {
        setSession({sessionToken: token, user: res});
        SetSuccess(true);
        setLoading(false);
        updateLS();
        return;
      }
      setLoading(false);
    };
    setAuthorize();
  }, []);

  if (!mounted) return {loading: true, success};
  if (!token) {
    setToken(null);
    setSession(null);
    return {loading: false, success};
  }
  return {loading, success};
}

export const useCheckAuthorize = () => async () => {
  try {
    const res = await authorizeQuery();
    return res?.body as authorizeRes;
  } catch (e) {
    return null;
  }
};

export const useViewer = () => {
  const user = useRecoilState(userState);

  return user[0];
};

export const useLogOut = () => {
  const setSession = useSetRecoilState(sessionState);

  return async () => {
    await logoutQuery();
    setToken(null);
    setSession(null);
  };
};
type LoginFormValues = {
  email?: string;
  password?: string;
};

export type useLoginRes = {
  error?: string;
  onSubmit: (values: LoginFormValues) => void;
  resetError?: () => void;
  loading?: boolean;
};

type authRes = {
  expires_in: number;
  id_token: string;
  token_type: string;
};

export const useLogin = (onSuccess?: () => void): useLoginRes => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const resetError = () => setError('');
  const setSession = useSetRecoilState(session);
  const setModals = useLoggedModals();
  const {updateLS} = useUpdateLastSession();

  const onSubmit = async (values: LoginFormValues) => {
    try {
      if (values.email && values.password) {
        setLoading(true);
        const res = await loginQuery({email: values.email, password: values.password});
        if (res) {
          setError('');
          const resBody = res?.body as authRes;
          const token = resBody.id_token;
          if (token) {
            setToken(token);
            const currentUser = await userQuery();
            setSession({sessionToken: token, user: currentUser.body});
            Cookies.set('id_token', token, {expires: resBody.expires_in});
            setModals(currentUser.body?.metadata?.lastSessionDate);
            updateLS();
            onSuccess?.();
          } else Cookies.remove('id_token');
        }
        if (!res) setError('Invalid password. Please enter valid password.');
      }
    } catch (e) {
      setError('Invalid password. Please enter valid password.');
    } finally {
      setLoading(false);
    }
    return false;
  };

  return {onSubmit, error, resetError, loading};
};

export type useForgotPasswordT = {
  statusViaEmail: number;
  resetStatues: () => void;
  resetByEmail: (val: any) => Promise<boolean | undefined>;
  updatePassword: (val: any) => Promise<boolean | undefined>;
  resetPassword: (val: any) => Promise<boolean | undefined>;
  isEmailReset: boolean;
  isCodeReset: boolean;
};

export const useForgotPassword = (): useForgotPasswordT => {
  const [statusViaEmail, setStatusViaEmail] = useState(0);
  const isEmailReset = true;
  const isCodeReset = false;

  const resetStatues = () => {
    setStatusViaEmail(0);
  };

  const resetByEmail = async (values: any) => {
    try {
      const body = toResetByEmailData(values);
      if (!body) return false;

      await SendResetPasswordEmail(body);
      setStatusViaEmail(1);
    } catch (e) {
      setStatusViaEmail(-1);
    }
  };
  const updatePassword = async (values: any) => {
    try {
      const body = toUpdatePasswordData(values);
      if (!body) return false;

      await UpdatePassword(body);
    } catch (e) {}
  };
  const resetPassword = async (values: any) => {
    try {
      const body = toResetPasswordData(values);
      if (!body) return false;

      await ResetPassword(body);
    } catch (e) {}
  };

  return {
    statusViaEmail,
    resetByEmail,
    updatePassword,
    resetPassword,
    isEmailReset,
    isCodeReset,
    resetStatues,
  };
};

export type useResetWithCode = {
  resetWithCode: (val: any) => Promise<boolean | undefined>;
  error: string;
};
export const useResetWithCode = (): useResetWithCode => {
  const [error, setError] = useState('');
  const [searchParams] = useSearchParams();
  const code = searchParams.get(searchKeys.magicCode);
  const email = searchParams.get(searchKeys.email);
  const navigate = useNavigate();

  const resetWithCode = async (values: any) => {
    try {
      const body = toResetWithCodeData({...values, email, code});
      if (!body) return false;

      const res = await RequestPasswordWithCode(body);
      if (res) navigate(route.login.path + '?updatedPass=true');
    } catch (e) {
      const error = JSON.parse(JSON.stringify(e));
      const errorMessage = error?.response?.text && JSON.parse(error?.response?.text)?.message;
      setError(errorMessage);
      return false;
    }
  };
  return {resetWithCode, error};
};

export const useUpdateLastSession = () => {
  const updateLS = async () => {
    try {
      const data = {
        metadata: {
          lastSessionDate: new Date(),
        },
      };
      await UpdateUser(data);
    } catch (e) {
      console.error(e);
    }
  };
  return {updateLS};
};
