import React, {useEffect} from 'react';
import {PageWrapper} from '../../components/Layout/Page';
import {PageHeader} from '../../components/Common/PageHeader';
import {useCurrentGroupFromUrlParam, useEditingGuestState, useGetCurrentGroup} from '../../hooks/state';
import {PageLoader} from '../../components/Common/PageLoader';
import {EmptyGroupState} from '../../components/Common/EmptyState/EmptyGroupState';
import {getGuestLink, mobileRoutes} from '../../constants/routes';
import {StarLine} from '../../components/Common/StarLine';
import {GroupInfoExpander} from '../../components/Common/GroupInfoExpander';
import {EditGuestForm} from './form';
import {EditGuestSubmit} from './submit';
import {useNavigate} from 'react-router-dom';

export const EditGuestPage = () => {
  useCurrentGroupFromUrlParam(); //store current group from url param if not stored
  const {currentGroup} = useGetCurrentGroup();
  const group = currentGroup.group;

  const {
    state: {guestId},
  } = useEditingGuestState();
  const navigate = useNavigate();

  useEffect(() => {
    if (!guestId) navigate(mobileRoutes.groups);
  }, []);
  if (!group && currentGroup?.loading) return <PageLoader />;
  if (!group) return <EmptyGroupState />;

  return (
    <PageWrapper>
      <PageHeader
        title="Guest Editing"
        subtitle="You can change your guest details."
        linkText="Guest List"
        link={getGuestLink(group)}
      />
      <EditGuestForm /> {/* FORM */}
      <StarLine /> {/* DIVIDER */}
      <GroupInfoExpander group={group} /> {/* GROUP INFO */}
      <EditGuestSubmit /> {/* SUBMIT BUTTON */}
    </PageWrapper>
  );
};
