import {AuthQueryList} from '../constants/api';
import {getQuery, postQuery, putQuery} from './hooks';
import {User} from '../types/auth';
import {
  loginRequest,
  magicLinkLoginReq,
  RequestPasswordWithCodeReq,
  ResetPasswordReq,
  sendMagicLinkReq,
  SendResetPasswordEmailReq,
  UpdatePasswordReq,
} from './types/auth';

export type authorizeRes = User | null;

export const authorizeQuery = async () => await getQuery(AuthQueryList.authorize());

export const sendMagicLinkQuery = async (body: sendMagicLinkReq) => postQuery(AuthQueryList.sendMagicLink()).send(body);

export const magicLinkLoginQuery = async (body: magicLinkLoginReq) =>
  postQuery(AuthQueryList.magicLinkLogin()).send(body);

export const logoutQuery = async () => await postQuery(AuthQueryList.logout());

export const userQuery = async () => await getQuery(AuthQueryList.user());

export const loginQuery = async (req: loginRequest) => await postQuery(AuthQueryList.login).send(req);

//password
export const UpdatePassword = async (body: UpdatePasswordReq) =>
  await postQuery(AuthQueryList.updatePassword).send(body);
export const RequestPasswordWithCode = async (body: RequestPasswordWithCodeReq) =>
  await postQuery(AuthQueryList.resetPasswordUsingCode).send(body);
export const SendResetPasswordEmail = async (body: SendResetPasswordEmailReq) =>
  await postQuery(AuthQueryList.requestPasswordReset).send(body);
export const ResetPassword = async ({userId, body}: ResetPasswordReq) =>
  await postQuery(AuthQueryList.resetPassword(userId)).send(body);

//update user
export const UpdateUser = async (body: any) => await putQuery(AuthQueryList.user()).send(body);
