import React from 'react';
import {Navigate, Route} from 'react-router-dom';
import {mobileRoutes} from './constants/routes';
import {PrivateRoute} from './external_usage';
import {GroupsPage} from './containers/GroupsPage';
import {SendCodePage} from './containers/SendCodePage';
import {GuestsPage} from './containers/GuestsPage';
import {EditGuestPage} from './containers/EditGuestPage';

export const PrivateMobileRoutes = (isAuth?: boolean) => [
  <Route
    key="m-groups"
    path={mobileRoutes.groups}
    element={
      <PrivateRoute isAuthorized={!!isAuth}>
        <GroupsPage />
      </PrivateRoute>
    }
  />,
  <Route
    key="m-send"
    path={mobileRoutes.send}
    element={
      <PrivateRoute isAuthorized={!!isAuth}>
        <SendCodePage />
      </PrivateRoute>
    }
  />,
  <Route
    key="m-guests"
    path={mobileRoutes.guests}
    element={
      <PrivateRoute isAuthorized={!!isAuth}>
        <GuestsPage />
      </PrivateRoute>
    }
  />,
  <Route
    key="m-guest-edit"
    path={mobileRoutes.guestEdit}
    element={
      <PrivateRoute isAuthorized={!!isAuth}>
        <EditGuestPage />
      </PrivateRoute>
    }
  />,
  <Route key="m-other" path={'*'} element={<Navigate to={mobileRoutes.groups} replace />} />,
];
