import React from 'react';
import styled from 'styled-components';
import {SubGuestT} from '../../external_usage';
import {StatusLabels} from './StatusLabels';
import {CodesLine} from './CodesLine';
import {GuestMenu} from './GuestMenu';

type PropsT = {
  guest?: SubGuestT;
  handleSend?: (guest?: SubGuestT) => void;
  handleEdit?: (guest?: SubGuestT) => void;
};

export const GuestCard: React.FC<PropsT> = ({guest, handleSend, handleEdit}) => {
  const onSend = () => handleSend?.(guest);
  const onEdit = () => handleEdit?.(guest);
  const sendText = guest?.promoCodes?.some((c) => c.outboundStatus === 'sent') ? 'Resend Codes' : 'Send Codes';
  return (
    <Wrapper>
      <StatusLabels codes={guest?.promoCodes} />
      <Text>{guest?.guestName}</Text>
      <Text>{guest?.guestEmail}</Text>
      <CodesLine codes={guest?.promoCodes} />
      <Menu sendText={sendText} onEdit={onEdit} onSend={onSend} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  width: 100%;
  padding: 16px;
  box-sizing: border-box;
  border-radius: 4px;
  border: 1px solid #cccfd0;

  background-color: #fff;
  position: relative;
`;

const Text = styled.div`
  font-size: 15px;
  font-weight: 700;
  line-height: 21px;
  letter-spacing: 0em;
  text-align: left;
`;

const Menu = styled(GuestMenu)`
  position: absolute;
  right: 8px;
  top: 10px;
`;
