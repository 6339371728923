import React, {PropsWithChildren, ReactNode} from 'react';
import styled from 'styled-components';
import {HEADER_HEIGHT} from '../../constants/layout';

type PropsT = PropsWithChildren & {
  header: ReactNode;
  showFooter?: boolean;
};

export const LayoutComponent: React.FC<PropsT> = ({children, header}) => {
  return (
    <LayoutWrapper>
      <Header>{header}</Header>
      <Body>{children}</Body>
    </LayoutWrapper>
  );
};

const LayoutWrapper = styled.div`
  height: 100svh;
  width: 100%;
  position: relative;

  box-sizing: border-box;
  padding-top: ${HEADER_HEIGHT}px;
  background-color: #fff;
`;

const Header = styled.header`
  position: fixed;
  left: 0;
  top: 0;
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  z-index: 100;
`;
const Body = styled.main`
  height: 100%;
`;

// const Footer = styled.div`
//   width: 100%;
//   padding: 13px 0 16px 0;
//   text-align: center;
// `;
