import {useState} from 'react';
import {valuesToLoginMagicLinkState, valuesToSendMagicLinkState} from '../helpers/auth';
import {magicLinkLoginQuery, sendMagicLinkQuery, userQuery} from '../queries/auth';
import {MagicLinkBodyT} from '../types/auth';
import {useSetRecoilState} from 'recoil';
import {session} from '../states/session';
import {setToken} from './auth';
import {useLoggedModals} from './onboarding';

export type useSendMagicLinkRes = {
  onSend: (values: any) => void;
  success?: boolean;
  error?: string;
};

export const useSendMagicLink = (onSuccess?: () => void): useSendMagicLinkRes => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | undefined>('');
  const onSend = async (values: any) => {
    try {
      setError('');
      setSuccess(false);

      const body = valuesToSendMagicLinkState(values);
      const res = await sendMagicLinkQuery(body);
      if (res.status) {
        onSuccess?.();
        setSuccess(true);
      }
    } catch (e) {
      setError("That email doesn't seem to be available in our system.");
    }
  };
  return {onSend, success, error};
};

export type useLoginMagicLinkRes = {
  onLogin: (values: MagicLinkBodyT) => void;
  success?: boolean;
  error?: string;
};

export const useLoginMagicLink = (redirect?: () => void): useLoginMagicLinkRes => {
  const [success, setSuccess] = useState(false);
  const [error, setError] = useState<string | undefined>('');
  const setSession = useSetRecoilState(session);
  const setModals = useLoggedModals();

  const onLogin = async (values: MagicLinkBodyT) => {
    try {
      setError('');
      setSuccess(false);

      const body = valuesToLoginMagicLinkState(values);
      const res = await magicLinkLoginQuery(body);
      if (res.status) {
        const token = res.body?.id_token;
        setToken(token);
        const currentUser = await userQuery();
        setSession({sessionToken: token, user: currentUser.body});
        setModals(currentUser.body?.metadata?.lastSessionDate);
        setSuccess(true);
        redirect?.();
      }
    } catch (e) {
      console.log(e);
      setError('Login error');
      redirect?.();
    }
  };
  return {onLogin, success, error};
};
