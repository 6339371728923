export const getModalText = ({
  uploadTriggered,
  success,
  error,
  fileTypeError,
  totalUploaded,
}: {
  uploadTriggered?: boolean;
  isOnboarding?: boolean;
  success: boolean;
  error: string;
  fileTypeError: boolean;
  totalUploaded: number;
}) => {
  const cancelText = uploadTriggered ? 'Cancel -  I will upload later' : 'Cancel';
  const ppTitle = success ? 'File Upload Processed' : 'Upload Contact File';
  const uploadText = 'Upload Contacts';
  const uploadSuccess = success
    ? {
        title: 'The file has been uploaded successfully.',
        descr: `Your portal now contains ${totalUploaded} contacts. Click “Continue” to contact details, edit contact information, change quantities, or upload more contacts to the portal.`,
      }
    : undefined;
  const uploadError = error
    ? {
        title: 'File upload error.',
        descr: `${error}`,
      }
    : null;
  const typeError = fileTypeError
    ? {
        title: 'Invalid file uploaded. Please try again.',
        descr: `Please make sure it's a CSV file and properly formatted`,
      }
    : null;

  return {cancelText, ppTitle, uploadSuccess, uploadError, typeError, uploadText};
};
