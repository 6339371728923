import {GuestT, SubGuestT} from '../external_usage';

export const sortOptions = {
  asc: 'A-Z',
  desc: 'Z-A',
  recently: 'Recently Updated',
};

export const uniFieldsGuests = (guests?: SubGuestT[], codeGroupGuests?: GuestT[]): SubGuestT[] | undefined => {
  return guests?.map((guest) => {
    const updatedAt = codeGroupGuests?.find((cg) => cg.id === guest?.guestId)?.updatedAt;
    return {...guest, updatedAt: updatedAt ? new Date(updatedAt) : undefined};
  });
};
