import {useRecoilState} from 'recoil';
import {currentGroupState, groupsState} from '../states/groups';
import {getCodeGroup, getCodeGroupGuests, getListCodeGroups} from '../external_usage';
import {useEffect} from 'react';
import {editingGuestState, editingGuestStateT, guestsState, sendCodeState} from '../states/codes';
import {useLocation} from 'react-router-dom';
import {exist} from '../helpers/common';
import {uniFieldsGuests} from '../helpers/guests';

export const useGetGroupsList = () => {
  const [groups, setState] = useRecoilState(groupsState);

  const getGroupList = async () => {
    setState((prev) => ({...prev, loading: true}));
    const res = await getListCodeGroups();
    setState((prev) => {
      return {...prev, groups: res.body, loading: false};
    });
  };
  return {...groups, getGroupList};
};

export const useGetCurrentGroup = () => {
  const [currentGroup, setState] = useRecoilState(currentGroupState);

  const getGroup = async (id: string | number) => {
    if (id === undefined) return;
    setState((prev) => ({...prev, loading: true}));
    const res = await getCodeGroup({groupId: String(id)});
    setState((prev) => {
      return {...prev, group: res.body, loading: false};
    });
  };

  const refetch = async () => {
    const id = currentGroup?.group?.id;
    if (!id) return;
    const res = await getCodeGroup({groupId: String(id)});
    setState((prev) => {
      return {...prev, group: res.body, loading: false};
    });
  };

  return {currentGroup, getGroup, refetch};
};

export const useCurrentGroupFromUrlParam = () => {
  const {search} = useLocation();
  const {
    currentGroup: {group},
    getGroup,
  } = useGetCurrentGroup();

  const groupId = new URLSearchParams(search).get('groupId');
  const alreadyExist = exist(group?.id) && String(group?.id) === groupId;

  useEffect(() => {
    if (!alreadyExist && groupId && exist(groupId)) getGroup(groupId);
  }, []);
};

export const useSetInitialCurrentGroup = () => {
  const [{groups}] = useRecoilState(groupsState);
  const [{group}, setState] = useRecoilState(currentGroupState);

  useEffect(() => {
    if (!group && groups) setState((prev) => ({...prev, group: groups?.[0]}));
  }, [!!groups, !!group]);
};

export const useChangeSendCodeState = () => {
  const [state, setState] = useRecoilState(sendCodeState);
  const changeName = (value?: any) => setState((prev) => ({...prev, name: value}));
  const changeEmail = (value?: any) => setState((prev) => ({...prev, email: value}));
  const changeQuantity = (value?: any) => setState((prev) => ({...prev, quantity: value}));

  const setError = (error?: string) => setState((prev) => ({...prev, error: error}));
  const clear = () => setState({});
  return {state, changeName, changeEmail, changeQuantity, setError, clear};
};

export const useGuestsState = () => {
  const {
    currentGroup: {group},
  } = useGetCurrentGroup();
  const [state, setState] = useRecoilState(guestsState);

  const getGuestList = async (groupId?: string | number) => {
    if (!exist(groupId)) return;
    try {
      setState((prev) => ({...prev, loading: true}));
    } catch (error) {
      console.error(error);
    } finally {
      setState((prev) => ({...prev, loading: false}));
    }
    const res = await getCodeGroupGuests({groupId: String(groupId)});
    const guests = uniFieldsGuests(res?.body?.guests, res?.body?.codeGroupGuests) || [];
    setState((prev) => ({...prev, guests}));
  };

  const refetchGuestList = async () => {
    const groupId = group?.id;
    if (!groupId) return;

    try {
      const res = await getCodeGroupGuests({groupId: String(groupId)});
      const guests = uniFieldsGuests(res?.body?.guests, res?.body?.codeGroupGuests) || [];
      setState((prev) => ({...prev, guests}));
    } catch (error) {
      console.error(error);
    }
  };

  return {guests: state?.guests, loading: state?.loading, getGuestList, refetchGuestList};
};

export const useEditingGuestState = () => {
  const [state, setState] = useRecoilState(editingGuestState);
  const setId = (value?: any) => setState((prev) => ({...prev, guestId: value}));
  const changeName = (value?: any) => setState((prev) => ({...prev, name: value}));
  const changeEmail = (value?: any) => setState((prev) => ({...prev, email: value}));
  const changeQuantity = (value?: any) => setState((prev) => ({...prev, quantity: value}));

  const setInitial = (value: editingGuestStateT) => setState(value);
  const setError = (error?: string) => setState((prev) => ({...prev, error: error}));
  const clear = () => setState({});
  return {state, changeName, changeEmail, changeQuantity, setId, setError, setInitial, clear};
};
