import React, {useEffect} from 'react';
import {Modal} from 'antd';
import {WarningOutlined} from '@ant-design/icons';

export enum ModalLvl {
  closed = 'closed',
  confirm = 'confirm',
  success = 'success',
}

type ShareModalProps = {
  modalLvl: ModalLvl;
  setModalLvl: (lvl: ModalLvl) => void;
  onSuccess?: () => void;
  totalQtyNotSentEmails?: () => string;
  emails?: any;
};

export const ShareModal: React.FC<ShareModalProps> = ({
  modalLvl,
  setModalLvl,
  onSuccess,
  totalQtyNotSentEmails,
  emails,
}) => {
  const totalEmails = totalQtyNotSentEmails?.();

  useEffect(() => {
    totalQtyNotSentEmails?.();
  }, [emails?.length]);

  useEffect(() => {
    if (modalLvl === ModalLvl.confirm) {
      Modal.confirm({
        title: `Are you sure you would like to bulk send ${totalEmails} of codes?`,
        content: (
          <div>
            Please make sure the emails are correct. With this action, your guests will receive emails with their
            assigned promo codes. This cannot be undone.
          </div>
        ),
        okText: 'Yes',
        cancelText: 'No',
        autoFocusButton: null,
        icon: <WarningOutlined />,
        onOk: () => {
          onSuccess?.();
          setModalLvl(ModalLvl.success);
        },
        onCancel: () => setModalLvl(ModalLvl.closed),
      });
    }
    if (modalLvl === ModalLvl.success) {
      Modal.success({
        title: `The promo codes have been shared successfully!`,
        content: (
          <div>
            Your guests will receive an email with Enchant Magic Link that will allow them to enjoy the promo code!
          </div>
        ),
        okText: 'Done',
        autoFocusButton: null,
        onOk: () => {
          setModalLvl(ModalLvl.closed);
        },
      });
    }
  }, [modalLvl]);

  return <></>;
};
