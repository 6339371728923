import React from 'react';
import {ItemType} from 'antd/es/menu/hooks/useItems';
import {Dropdown, Menu} from 'antd';
import {ButtonMore, ButtonTypes} from '../../../ui-kit/Button';
import {ModalLvl} from './Table';
import {ViewCodeMap} from '../../../helpers/table';
import {CodeSendStatuses, CodeViewFields, CodeViewStateChange, CodeViewStateT} from '../../../types/codes';
import {PromoStateT} from '../../../hooks/groups';
import {ActionBtn, ActionColumn, CodeInput, CustomInfoButton, CodeColumn, MenuAction} from './styles';

export type CodeViewInputItemPropsT = {
  state?: CodeViewStateT;
  field: CodeViewFields;
  onChange?: CodeViewStateChange;
  editingId?: string;
  email?: string;
  guestName?: string;
  id?: string;
};

export const CodeViewInputItem: React.FC<CodeViewInputItemPropsT> = ({
  editingId,
  id,
  email,
  guestName,
  onChange,
  field,
  state,
}) => {
  const isCurrentEditing = id && editingId === id;
  if (!id) return null;
  const handleChange = (e: any) => onChange?.({key: field, id, value: e?.target?.value});
  const value = field === CodeViewFields.email ? email : guestName;

  //remove actions
  return <CodeColumn>{value && <div>{value}</div>}</CodeColumn>;

  if (isCurrentEditing)
    return (
      <CodeColumn>
        <CodeInput placeholder={`Type ${field}...`} value={state?.[field] || ''} onChange={handleChange} />
      </CodeColumn>
    );

  return (
    <CodeColumn>
      {value || email ? <div>{value}</div> : <CodeInput placeholder={`Type ${field}...`} onChange={handleChange} />}
    </CodeColumn>
  );
};

export type CodeViewPromoItemPropsT = {code?: string};
export const CodeViewPromoItem: React.FC<CodeViewPromoItemPropsT> = ({code}) => {
  return (
    <CodeColumn>
      <div>{code}</div>
    </CodeColumn>
  );
};

export type CodeViewActionsItemProps = {
  edit?: boolean;
  id?: string;
  codeStatus?: CodeSendStatuses;
  onUpdateCode?: (id: string) => void;
  onCancelEdit?: () => void;
  onSendCode?: (id?: string) => void;
  setFocusId?: (id?: string) => void;
  loading?: boolean;
  setEditingId?: (id?: string) => void;
  editingId?: string;
  setDelPopup?: (lvl: ModalLvl) => void;
  state?: PromoStateT;
  email?: string;
};

export const CodeViewActionsItem: React.FC<CodeViewActionsItemProps> = ({
  codeStatus,
  onSendCode,
  id,
  setFocusId,
  loading,
  setEditingId,
  editingId,
  setDelPopup,
  email,
  state,
  onUpdateCode,
}) => {
  const isCurrentEditing = id && editingId === id;
  const setEdit = () => setEditingId?.(id);
  const onCancelEdit = () => setEditingId?.('');
  const onSend = () => {
    setFocusId?.(id);
    onSendCode?.(id);
  };
  if (!id) return null;
  const canSave = state?.[id]?.valid;
  const onDelete = () => {
    setFocusId?.(id);
    setDelPopup?.(ModalLvl.confirm);
  };

  const onUpdate = () => onUpdateCode?.(id);
  // remove actions
  return (
    <ActionColumn>
      {codeStatus === CodeSendStatuses.queued && (
        <CustomInfoButton variant={ViewCodeMap?.sent}>Queued</CustomInfoButton>
      )}
      {codeStatus === CodeSendStatuses.sent && (
        <CustomInfoButton variant={ViewCodeMap?.sent}>Code Sent</CustomInfoButton>
      )}
    </ActionColumn>
  );

  if (!email)
    return (
      <ActionColumn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={onUpdate} disabled={loading || !canSave}>
          Save
        </ActionBtn>
      </ActionColumn>
    );

  if (isCurrentEditing)
    return (
      <ActionColumn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={onCancelEdit} disabled={loading}>
          Cancel
        </ActionBtn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={onUpdate} disabled={loading || !canSave}>
          Update
        </ActionBtn>
      </ActionColumn>
    );

  if (codeStatus === CodeSendStatuses.notsent && email)
    return (
      <ActionColumn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={setEdit} disabled={loading}>
          Edit
        </ActionBtn>
        <ActionBtn variant={ButtonTypes.secondary} onClick={onSend} disabled={loading}>
          Send
        </ActionBtn>
        <ActionBtn variant={ButtonTypes.tertiary} onClick={onDelete} disabled={loading}>
          Delete
        </ActionBtn>
      </ActionColumn>
    );

  return (
    <ActionColumn>
      {codeStatus === CodeSendStatuses.queued && (
        <CustomInfoButton variant={ViewCodeMap?.sent}>Queued</CustomInfoButton>
      )}
      {codeStatus === CodeSendStatuses.sent && (
        <CustomInfoButton variant={ViewCodeMap?.sent}>Code Sent</CustomInfoButton>
      )}
      <>{id && email && <MenuMorePromocode onEdit={setEdit} />}</>
    </ActionColumn>
  );
};

export type MenuMorePromocodeProps = {
  id?: string;
  onEdit?: () => void;
  onSend?: () => void;
};

export const MenuMorePromocode: React.FC<MenuMorePromocodeProps> = ({onEdit}) => {
  const ddActions: ItemType[] = [{key: 1, label: <MenuAction onClick={onEdit}>Edit</MenuAction>}];

  return (
    <Dropdown overlay={<Menu items={ddActions} />} trigger={['click']}>
      <ButtonMore />
    </Dropdown>
  );
};
