import {atom} from 'recoil';
import {SubGuestT} from '../external_usage';

export type SendCodeStateT = {
  email?: string;
  name?: string;
  quantity?: number;
  error?: string;
};

export const sendCodeState = atom<SendCodeStateT>({
  key: 'send-code-state',
  default: {},
});

export type guestsStateList = {
  guests?: SubGuestT[];
  loading?: boolean;
};

export const guestsState = atom<guestsStateList>({
  key: 'guests-state',
  default: {loading: true},
});

export type editingGuestStateT = {
  guestId?: string | number;
  email?: string;
  name?: string;
  quantity?: number;
  error?: string;
};

export const editingGuestState = atom<editingGuestStateT>({
  key: 'editing-guest-state',
  default: {},
});
