import {Form, Typography} from 'antd';
import styled from 'styled-components';
import {Button} from '../../ui-kit/Button';
import {FormItem} from '../../ui-kit/Input';
import {StaticCard} from '../../ui-kit/StaticCard';
const {Text, Title} = Typography;
export const PageWrapper = styled.div`
  width: 100%;
  display: flex;
  box-sizing: border-box;
  flex-direction: column;
  padding: 40px 0;
  align-items: center;
`;

export const UploadContainer = styled.div`
  width: 100%;
  max-width: 550px;
  flex-direction: column;
  display: flex;
  align-items: center;
`;

export const CodeFormItem = styled(FormItem)`
  && {
    margin-bottom: 22px;
  }
  .ant-form-item-control,
  .ant-form-item-control-input-content {
    background-color: ${({theme}) => theme.palette.background.neutral};
    width: 457px;
  }
`;

export const ButtonFormItem = styled(FormItem)`
  && {
    margin-bottom: 8px;
  }
`;

export const CodeForm = styled(Form)`
  padding: 18px 27px 21px 27px;
  margin-top: 45px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.background.darkGray};
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.background.white};
  max-width: 510px;
`;

export const FormButton = styled(Button)`
  width: 100%;
  font-size: 16px;
  height: 40px;
`;

export const EmailUploadCardsRow = styled.div`
  display: flex;
  margin-bottom: 24px;
  justify-content: space-between;
`;

export const StaticEmailCard = styled(StaticCard)`
  width: 143px;
  margin-right: 0px;
`;

export const FormTitle = styled(Title).attrs({level: 3})`
  padding-bottom: 12px;
  display: block;
`;
export const FormTextRow = styled(Text)`
  margin-bottom: 20px;
  display: block;
`;
export const HintText = styled(Text)`
  margin-top: 6px;
  margin-left: 4px;
  display: block;
  font-size: 10px;
  color: ${({theme}) => theme.palette.text.secondary};
  & > span {
    text-decoration: underline;
  }
`;

export const SelectGroupLabel = styled(Text)`
  margin-right: 15px;
`;

export const SelectGroupContainer = styled.div`
  padding: 18px 27px 21px 27px;
  margin-top: 20px;
  margin-bottom: -25px;
  box-sizing: border-box;
  border: 1px solid ${({theme}) => theme.palette.background.darkGray};
  border-radius: 8px;
  background-color: ${({theme}) => theme.palette.background.white};
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 512px;
  & .ant-select {
    width: 320px;
  }
`;
