import React from 'react';
import {notification} from 'antd';
import styled from 'styled-components';
import {PromoCodeT} from '../../external_usage';
import {pluralize} from '../../helpers/common';

export const StatusLabels = ({codes}: {codes?: PromoCodeT[]}) => {
  const sent = codes?.filter((el) => el?.outboundStatus === 'sent');
  const notsent = codes?.filter((el) => el?.outboundStatus === 'notsent');
  const sentCount = sent?.length ? pluralize(sent.length, 'code') : '';
  const notsentCount = notsent?.length ? pluralize(notsent.length, 'code') : '';

  const copySentCodes = () => {
    navigator.clipboard.writeText(sent?.map((el) => el?.code)?.join(', ') || '');
    notification.success({
      message: `${sentCount} copied to clipboard!`,
      placement: 'topRight',
      duration: 1,
    });
  };
  const copyNotSentCodes = () => {
    navigator.clipboard.writeText(notsent?.map((el) => el?.code)?.join(', ') || '');
    notification.success({
      message: `${notsentCount} copied to clipboard!`,
      placement: 'topRight',
      duration: 1,
    });
  };
  return (
    <Statuses>
      {!!sentCount && (
        <StatusLabel variant="primary" onClick={copySentCodes}>
          {sentCount} sent
        </StatusLabel>
      )}
      {!!notsentCount && (
        <StatusLabel variant="secondary" onClick={copyNotSentCodes}>
          {notsentCount} assigned
        </StatusLabel>
      )}
    </Statuses>
  );
};

const Statuses = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  margin-bottom: 6px;
`;

const StatusLabel = styled.div<{variant: 'primary' | 'secondary'}>`
  padding: 2px 4px 2px 4px;
  border-radius: 4px;

  font-size: 12px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;

  ${({variant}) => {
    if (variant === 'primary')
      return `
  background: #227B75;
  color: #fff;
  `;
    if (variant === 'secondary')
      return `
  background: #BECDCC;
  color: #000000;
  `;
  }}
`;
