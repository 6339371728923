import React, {useRef, useState} from 'react';
import {format} from 'date-fns';
import {CSVLink} from 'react-csv';
import {ButtonTypes} from '../../../ui-kit/Button';
import {DownloadCSVButton, GetCSVbutton} from './styles';
import {GenerateCSVResT} from '../../../types/codes';

export const DownloadCSV: React.FC<{downloadCSV?: () => GenerateCSVResT}> = ({downloadCSV}) => {
  const csvLinkRef = useRef<CSVLink & HTMLAnchorElement & {link: HTMLAnchorElement}>(null);
  const [csv, setCsv] = useState<(string | null | undefined)[][]>([]);
  const onDownloadCSVclick = () => {
    const res = downloadCSV?.();
    if (!res?.headers || !res?.data) return;
    setCsv([res.headers, ...res.data]);
    csvLinkRef?.current?.link.setAttribute(
      'download',
      `code_list_view_${format(new Date(), 'P').replaceAll('/', '_')}`,
    );

    setTimeout(() => {
      csvLinkRef?.current?.link.click();
    }, 500);
  };
  return (
    <>
      {downloadCSV && (
        <GetCSVbutton variant={ButtonTypes.secondary} onClick={onDownloadCSVclick}>
          Download Contacts
        </GetCSVbutton>
      )}
      <DownloadCSVButton data={csv} target="_blank" ref={csvLinkRef} />
    </>
  );
};
