import {useEffect, useState} from 'react';
import {useChangeSendCodeState, useGetCurrentGroup} from './state';
import {MAX_CODES_PER_GUEST, addCodeGroupGuest, getErrorResponse, validateEmail} from '../external_usage';
import {exist} from '../helpers/common';
import {notification} from 'antd';

export const useSendCodeForm = () => {
  const {state, changeName, changeEmail, changeQuantity} = useChangeSendCodeState();
  const handleChange = (field?: string, value?: string | number) => {
    if (field === 'name') return changeName(value);
    if (field === 'email') return changeEmail(value);
    if (field === 'quantity') return changeQuantity(value);
  };

  return {values: state, error: state?.error, handleChange};
};

export const useSendCodes = (onSuccess?: () => void) => {
  const {
    currentGroup: {group},
    refetch,
  } = useGetCurrentGroup();
  const {state, clear} = useChangeSendCodeState();
  const [isValid, setValid] = useState(false);
  const [loading, setLoading] = useState(false);

  const checkQuantity = () => {
    if (!group?.codesUploaded || !group?.codesAssigned) return true;
    const selected = state?.quantity || 0;
    const available = group?.codesUploaded - group?.codesAssigned;
    if (available >= selected) return true;

    notification.error({
      message: 'Error',
      description: `Please reduce the number of promo codes you send. There are only ${available} codes available`,
    });
    return false;
  };

  const onSubmit = async () => {
    if (!isValid || !exist(group?.id)) return;
    if (!state?.email || !state?.name || !state?.quantity) return;
    if (!checkQuantity()) return;

    try {
      setLoading(true);
      await addCodeGroupGuest({
        groupId: String(group?.id),
        body: {email: state?.email, name: state?.name, quantity: state?.quantity},
      });
      refetch();
      onSuccess?.();
      clear();
    } catch (e) {
      console.error(e);
      const error = getErrorResponse(e);
      notification.error({
        message: 'Error',
        description: error?.message,
      });
    } finally {
      setLoading(false);
    }
  };

  const validate = () => {
    if (!validateEmail(state?.email)) return setValid(false);
    if ((state?.name?.length || 0) < 1) return setValid(false);
    if (!state?.quantity || state?.quantity > MAX_CODES_PER_GUEST) return setValid(false);
    setValid(true);
  };

  useEffect(() => {
    validate();
  }, [state?.email, state?.name, state?.quantity]);
  return {isValid, onSubmit, loading};
};
