import React from 'react';
import {PromoCodeT} from '../../external_usage';
import styled from 'styled-components';
import {notification} from 'antd';

export const CodesLine = ({codes}: {codes?: PromoCodeT[]}) => {
  const sent = codes?.filter((el) => el?.outboundStatus === 'sent');
  const copyCode = (code?: string) => () => {
    navigator.clipboard.writeText(code || '');
    notification.success({
      message: `${code} copied to clipboard!`,
      placement: 'topRight',
      duration: 1,
    });
  };
  return (
    <Line>
      {sent?.map((el) => (
        <Label key={el?.code} onClick={copyCode(el?.code)}>
          {el?.code}
        </Label>
      ))}
    </Line>
  );
};

const Line = styled.div`
  display: flex;
  align-items: center;
  gap: 9px;
  margin-top: 10px;
  flex-wrap: wrap;
`;

const Label = styled.div`
  width: 66px;
  box-sizing: border-box;
  padding: 2px 6px 2px 6px;
  border-radius: 4px;

  font-size: 10px;
  font-weight: 400;
  line-height: 16px;
  letter-spacing: 0em;
  color: #000000;
  background: #f3efea;
`;
