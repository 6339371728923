import React from 'react';
import {
  CardDateInfoLabel,
  CardInfoRow,
  CardInfoValue,
  CardsRow,
  TableCardsWrapper,
  TableTitleCard,
  TableCardsOptionsWrapper,
  SendCodesButtonWrapper,
  UploadButton,
  StyledSelect,
} from './styles';
import {format} from 'date-fns';
import {StaticCard} from '../../../ui-kit/StaticCard';
import {SelectOptionT} from '../../../types/helpers';
import {CodeCardT, CodeStatusTypes} from '../../../types/codes';
import {ButtonTypes} from '../../../ui-kit/Button';

type TableCardsProps = {
  cards?: CodeCardT[];
  createdAt?: string | number;
  updatedAt?: string | number;
  filterValue?: CodeStatusTypes | string;
  setFilter?: (v: CodeStatusTypes | string) => void;
  filterOptions?: SelectOptionT[];
  upper?: boolean;
  onUpload?: () => void;
};

export const TableCards: React.FC<TableCardsProps> = ({
  cards,
  createdAt,
  updatedAt,
  filterValue,
  // setFilter,
  filterOptions,
  onUpload,
}) => {
  return (
    <TableCardsWrapper>
      <CardsRow>
        <TableTitleCard>
          <CardInfoRow>
            <CardDateInfoLabel>Created</CardDateInfoLabel>
            <CardInfoValue>{createdAt && format(new Date(createdAt), 'd LLL u')}</CardInfoValue>
          </CardInfoRow>
          <CardInfoRow>
            <CardDateInfoLabel>Updated</CardDateInfoLabel>
            <CardInfoValue>{updatedAt && format(new Date(updatedAt), 'd LLL u')}</CardInfoValue>
          </CardInfoRow>
        </TableTitleCard>
        {cards?.map((el) => (
          <StaticCard key={el.label} {...el} />
        ))}
      </CardsRow>
      <TableCardsOptionsWrapper>
        <SendCodesButtonWrapper>
          <UploadButton type={'primary'} variant={ButtonTypes.primary} onClick={onUpload}>
            Upload Contacts
          </UploadButton>
        </SendCodesButtonWrapper>
        <StyledSelect
          options={filterOptions}
          value={filterValue}
          // onChange={setFilter}
        />
      </TableCardsOptionsWrapper>
    </TableCardsWrapper>
  );
};
