import React, {useState} from 'react';
import {
  StyledModalFileLink,
  UploadModalActions,
  UploadModalApplyBtn,
  UploadModalCancelBtn,
  UploadModalDescr,
  UploadModalTitle,
  UploadModalWrapper,
} from '../../../ui-kit/Modal/styles';
import {WrapperLinks, QuestionCircleStyled, MoreInfoRow, WideButton, SizeContent} from './styles';
import {FileUpload} from '../../../ui-kit/Input/FileUpload';
import {links} from './OnboardingModals';
import {getModalText} from '../../../helpers/upload';
import {UploadFile} from 'antd/lib/upload/interface';

type UploadModalPropsT = {
  setFiles?: (files: any) => void;
  onAdd: (value: any[]) => Promise<any>;
  onCancel: () => void;
  onUploadSuccess: () => void;
  uploadTriggered?: boolean;
  isOnboarding?: boolean;
};

export const UploadModal: React.FC<UploadModalPropsT> = ({
  onAdd,
  onCancel,
  uploadTriggered,
  onUploadSuccess,
  isOnboarding,
}) => {
  const [fileList, setFileList] = useState([]);
  const [fileTypeError, setFileTypeError] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [totalUploaded, setTotalUploaded] = useState(0);
  const [error, setError] = useState('');

  const resetError = () => {
    setFileTypeError(false);
    setError('');
    setFileList([]);
  };
  const props = {
    onRemove: (file: any) => {
      // eslint-disable-next-line
      //@ts-ignore
      const index = fileList.indexOf(file);
      const newFileList = fileList.slice();
      newFileList.splice(index, 1);
      setFileList(newFileList);
      setFileTypeError(false);
    },
    // eslint-disable-next-line
    //@ts-ignore
    beforeUpload: (file) => {
      setFileTypeError(false);
      if (file.type !== 'text/csv') {
        setFileTypeError(true);
        return false;
      }
      // eslint-disable-next-line
      //@ts-ignore
      setFileList([...fileList, file]);
      return false;
    },
    fileList,
    accept: '.csv',
    name: 'emails',
  };

  const apply = async () => {
    setError('');
    setSuccess(false);
    setLoading(true);
    const res = await onAdd?.(fileList);
    if (res?.success) {
      setSuccess(true);
      setTotalUploaded(res?.total);
    }
    if (res?.error) setError(res?.error?.message);
    setLoading(false);
  };

  const {ppTitle, uploadError, uploadSuccess, typeError, cancelText, uploadText} = getModalText({
    uploadTriggered,
    isOnboarding,
    success,
    error,
    fileTypeError,
    totalUploaded,
  });
  return (
    <UploadModalWrapper>
      <UploadModalTitle>{ppTitle}</UploadModalTitle>

      <FileUpload
        woTheme={true}
        onResultClick={resetError}
        descr="Upload a contact file in .csv format.
        File must have contact data in the following columns: name, email, quantity. All contacts must be listed in separate rows."
        title="Drag and drop a file here or click the icon to choose one"
        success={uploadSuccess}
        loading={loading}
        error={uploadError || typeError}
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        //@ts-ignore
        itemRender={(originNode, file) => <UploadListItem originNode={originNode} file={file} />}
        {...props}
      />
      {!uploadTriggered && !success && (
        <WrapperLinks>
          <UploadModalDescr>
            Download the CSV template:{' '}
            <StyledModalFileLink href="https://storage.googleapis.com/enc-assets/portals/promo_code_guests.csv">
              enchant-csv-email-template.csv
            </StyledModalFileLink>
          </UploadModalDescr>
          <StyledModalFileLink
            target="_blank"
            href="https://enchantlabs.notion.site/CSV-Guide-for-Codes-Distribution-c15b083560e14336ad4124a34860f833">
            <QuestionCircleStyled />
          </StyledModalFileLink>
        </WrapperLinks>
      )}
      {!success ? (
        <>
          <UploadModalActions>
            <UploadModalCancelBtn onClick={onCancel}>{cancelText}</UploadModalCancelBtn>
            <UploadModalApplyBtn onClick={apply} disabled={!fileList?.length}>
              {uploadText}
            </UploadModalApplyBtn>
          </UploadModalActions>
          <MoreInfoRow>
            <b>Need more info? </b>{' '}
            <a href={links.more} target="_blank" rel="noreferrer">
              Visit our help resource
            </a>
          </MoreInfoRow>
        </>
      ) : (
        <WideButton onClick={onUploadSuccess}>Continue</WideButton>
      )}
    </UploadModalWrapper>
  );
};

interface UploadListItemProps {
  originNode: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  file: UploadFile<any>;
}

const UploadListItem = ({originNode, file}: UploadListItemProps) => {
  const size = `${file?.size} KB`;
  return <SizeContent size={size}>{originNode}</SizeContent>;
};
