import {atom} from 'recoil';
import {CodeGroupT} from '../external_usage';

export type GroupsStateT = {
  groups?: CodeGroupT[];
  loading: boolean;
};

export const groupsState = atom<GroupsStateT>({
  key: 'groups-state',
  default: {
    loading: true,
  },
});

export type CurrentGroupsStateT = {
  group?: CodeGroupT;
  loading: boolean;
};

export const currentGroupState = atom<CurrentGroupsStateT>({
  key: 'group-state',
  default: {
    loading: true,
  },
});
