import React from 'react';
import styled from 'styled-components';
import {PrimaryButton} from '../../components/Common/Button/PrimaryButton';
import {useGuestUpdate} from '../../hooks/guests';

export const EditGuestSubmit = () => {
  const {isValid, onSubmit, loading} = useGuestUpdate();
  return (
    <ButtonWrapper>
      <PrimaryButton onClick={onSubmit} disabled={!isValid || loading}>
        Update
      </PrimaryButton>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  position: fixed;
  box-sizing: border-box;
  padding: 0 16px;
  bottom: 24px;
  left: 0;
  width: 100%;
`;
