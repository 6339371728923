import React from 'react';
import RoutesSwitch from './Routes';
import {Normalize} from 'styled-normalize';
import {ThemeProvider} from 'styled-components';
import {defaultTheme} from './ui-kit/theme/theme';
import 'antd/dist/antd.css';
import './ui-kit/fonts/fonts.css';
import './ui-kit/Modal/styles.css';
import {Layout as DesktopLayout} from './containers/Layout';
import CurrentUser from './containers/CurrentUser';
import RecoilProvider from './containers/RecoilProvider';
import {IsMobileVersion} from './helpers/helpers';
import {MobileLayout} from './mobile/containers/Layout';

const Layout = IsMobileVersion ? MobileLayout : DesktopLayout;
function App() {
  return (
    <>
      <RecoilProvider>
        <CurrentUser>
          <ThemeProvider theme={defaultTheme}>
            <Layout>
              <RoutesSwitch />
            </Layout>
            <Normalize />
          </ThemeProvider>
        </CurrentUser>
      </RecoilProvider>
    </>
  );
}

export default App;
