import React from 'react';
import styled from 'styled-components';
import {PrimaryButton} from '../../components/Common/Button/PrimaryButton';
import {useSendCodes} from '../../hooks/codes';
import {Modal} from 'antd';
import {SendConfirmModal, SendSuccessModal} from '../../helpers/text';
import {useNavigate} from 'react-router-dom';
import {getGuestLink} from '../../constants/routes';
import {useGetCurrentGroup} from '../../hooks/state';

export const SendCodesSubmit = () => {
  const {currentGroup} = useGetCurrentGroup();
  const navigate = useNavigate();

  const openSuccessModal = () =>
    Modal.success({
      title: SendSuccessModal.title,
      content: SendSuccessModal.content,
      onOk: () => navigate(getGuestLink(currentGroup.group)),
      okText: 'Done',
    });

  const {isValid, onSubmit, loading} = useSendCodes(openSuccessModal);

  const openConfirm = () =>
    Modal.confirm({
      title: SendConfirmModal.title,
      content: SendConfirmModal.content,
      onOk: onSubmit,
      okText: 'Yes',
      cancelText: 'No',
    });

  return (
    <ButtonWrapper>
      <PrimaryButton onClick={openConfirm} disabled={!isValid || loading}>
        SEND CODE
      </PrimaryButton>
    </ButtonWrapper>
  );
};

const ButtonWrapper = styled.div`
  position: absolute;
  box-sizing: border-box;
  padding: 0 16px;
  bottom: 24px;
  left: 0;
  width: 100%;
  background-color: #fff;
  z-index: 100;
`;
