import React, {PropsWithChildren} from 'react';
import styled from 'styled-components';
import tree from '../../assets/trees-gold-1.png';

type PropsT = PropsWithChildren & {
  showIllustration?: boolean;
  scrollFixed?: boolean;
};

export const PageWrapper: React.FC<PropsT> = ({children, showIllustration, scrollFixed}) => {
  return (
    <Wrapper $image={showIllustration} $scrollFixed={scrollFixed}>
      {children}
    </Wrapper>
  );
};

const Wrapper = styled.div<{$image?: boolean; $scrollFixed?: boolean}>`
  height: 100%;
  padding: 24px 16px;
  box-sizing: border-box;
  position: relative;

  ${({$image}) =>
    $image &&
    `
  background-image: url(${tree});
  background-size: 91px 158px;
  background-repeat: no-repeat;
  background-position: 94% 88%;
  `}

  overflow-x: clip;
  overflow-y: ${({$scrollFixed}) => ($scrollFixed ? 'hidden' : 'scroll')};

  &::-webkit-scrollbar {
    width: 2px;
  }

  &::-webkit-scrollbar-track {
    background: transparent;
  }

  &::-webkit-scrollbar-thumb {
    background: #fafafa;
    border-radius: 4px;
  }

  &::-webkit-scrollbar-thumb:hover {
    background: #cccfd0;
  }
  background-color: #fff;
`;
