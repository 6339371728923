import {useState} from 'react';
import {sendNotSentGuestEmailsQuery, uploadGuestEmailsQuery} from '../queries/emails';
import {emailState, emailStateVal} from '../components/Groups/View/Table';
// import {usePromoCodesActions, useSendCode} from "./groups";
import {emailStateToArray} from '../helpers/codes';
import {codeState as codeStateT, useGuestChangeCodesT, usePromoCodesActions, useSendCode} from './groups';
import {codeItemT, UsedCodesT} from '../types/codes';
import {MAX_CODES_PER_GUEST, getErrorResponse} from '../helpers/helpers';
import {addCodeGroupGuest, updateCodeGroupGuest} from '../queries/groups';
import {notification} from 'antd';

export type useUploadEmailsT = {
  uploadResult: {total?: number; success?: boolean};
  upload: (file: any, onSuccess?: () => void) => Promise<void>;
  uploadLoading?: boolean;
};

export const useUploadEmails = (groupIDs: string[], onSuccess1?: () => void) => {
  const [uploadResult, setUploadResult] = useState({});
  const [uploadLoading, setUploadLoading] = useState(false);
  const upload = async (file: any, onSuccess?: () => void) => {
    const groupId = groupIDs[0];
    if (!groupId) return;
    setUploadLoading(true);

    try {
      let result;
      if (groupIDs.length === 1) {
        const body = {groupId, body: {guestEmails: file}};
        const res = await uploadGuestEmailsQuery(body);
        if (res) {
          onSuccess?.();
          onSuccess1?.();
          setUploadResult({total: res?.body?.length || 0, success: true});
          result = {total: res?.body?.length || 0, success: true};
        }
      } else {
        let codes = 0;
        const res = await Promise.all(
          groupIDs.map((el) => {
            const body = {groupId: el, body: {guestEmails: file}};
            return uploadGuestEmailsQuery(body).then((res) => (codes = codes + (res?.body || 0)));
          }),
        );
        if (res) {
          onSuccess1?.();
          onSuccess?.();
          setUploadResult({total: codes, success: true});
          result = {total: codes, success: true};
        }
      }

      setUploadLoading(false);
      return result;
    } catch (e) {
      setUploadLoading(false);
      console.log(e);
      return {error: getErrorResponse(e)};
    }
  };
  return {upload, uploadResult, uploadLoading};
};

export type useSendNotSentEmailsT = {
  send: (onSuccess?: () => void) => Promise<void>;
  loading?: boolean;
};

export const useSendNotSentEmails = (groupIDs: string[]): useSendNotSentEmailsT => {
  const [loading, setLoading] = useState(false);

  const send = async (onSuccess?: () => void) => {
    const groupId = groupIDs[0];
    setLoading(true);
    if (!groupId) return;
    try {
      if (groupIDs.length === 1) {
        const res = await sendNotSentGuestEmailsQuery({groupId});
        if (res) onSuccess?.();
      } else {
        const res = await Promise.all(
          groupIDs.map((el) => {
            return sendNotSentGuestEmailsQuery({groupId: el});
          }),
        );
        if (res) onSuccess?.();
      }
      setLoading(false);
    } catch (e) {
      setLoading(false);
      console.log(e);
    }
  };
  return {send, loading};
};

export type useSetEmailsT = {
  emails: emailState;
  handleSetEmail: (val: emailStateVal) => void;
  handleSetName: (val: emailStateVal) => void;
  reset: () => void;
};

export const useSetEmails = (): useSetEmailsT => {
  const [emails, setEmails] = useState<emailState>({});
  const handleSetEmail = (val: emailStateVal) =>
    setEmails((prev) => ({...prev, [val.key]: {...prev?.[val.key], email: val?.email}}));
  const handleSetName = (val: emailStateVal) =>
    setEmails((prev) => ({...prev, [val.key]: {...prev?.[val.key], name: val?.name}}));
  const reset = () => setEmails({});
  return {emails, handleSetEmail, handleSetName, reset};
};

type multipleUpdateProps = {
  groupId?: string;
  onSuccess?: () => void;
  emails?: emailState;
  usedCodes?: UsedCodesT;
  refetchGroup?: () => void;
};

export const useMultipleUpdate = ({groupId, onSuccess}: multipleUpdateProps) => {
  const {onUpdate} = usePromoCodesActions(groupId);
  const updateAll = async (emails: emailState) => {
    if (!groupId) return false;
    try {
      const rows = emailStateToArray(emails);
      const res = await Promise.all(rows.map((el) => onUpdate({codeId: el?.id, email: el?.email})));
      if (res) onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };

  return {updateAll};
};

type useMultipleSendProps = {
  groupId?: string;
  onSuccess?: () => void;
};

export const useMultipleSend = ({groupId, onSuccess}: useMultipleSendProps) => {
  const {sendCode} = useSendCode(groupId);
  const sendAll = async (emails: emailState) => {
    if (!groupId) return false;
    try {
      const rows = emailStateToArray(emails).map((el) => el.id);
      const res = await Promise.all(rows.map((id) => sendCode(id)));
      if (res) onSuccess?.();
      onSuccess?.();
    } catch (e) {
      console.log(e);
    }
  };

  return {sendAll};
};

export type updateTargetT = (
  codeState: useGuestChangeCodesT['codes'],
  skip: boolean,
  unused?: codeItemT[],
  onSuccess2?: (id?: string) => void,
) => (id?: string | number, eCodeState?: codeStateT, isAdd?: boolean) => Promise<false | true>;

export const openNotifError = (message?: string) => {
  notification['error']({
    message: 'Error',
    description: message,
    duration: 6,
  });
};

export const useUpdateTarget = ({groupId, onSuccess, refetchGroup, emails, usedCodes}: multipleUpdateProps) => {
  const updateTarget: updateTargetT =
    (
      codeState: useGuestChangeCodesT['codes'],
      skip: boolean,
      unused?: codeItemT[],
      onSuccess2?: (id?: string) => void,
    ) =>
    async (id?: string | number, eCodeState?: codeStateT, isAdd?: boolean) => {
      if (!groupId || !emails || id === undefined) return false;
      try {
        const numId = Number(id);
        const used = usedCodes?.find((el) => String(el?.id) === String(id));
        if (numId !== -1 && !used?.used?.length) return false;

        const guestData = eCodeState || emails?.[numId];
        // const codeCount = (codeState?.[numId] || 0) - (used?.used?.length || 0);
        // const codesToPurchase = [...(used?.used || []), ...(unused?.slice(0, codeCount) || [])];
        if ((codeState?.[numId] || 0) > MAX_CODES_PER_GUEST) return false;
        if (skip || !codeState || codeState?.[numId] === 0) return false;

        if (!guestData.email || !guestData.name) return false;
        //add part
        if (isAdd) {
          const res = await addCodeGroupGuest({
            groupId,
            body: {email: guestData.email, name: guestData.name, quantity: codeState?.[numId] || 0},
          });
          if (res) {
            onSuccess?.();
            onSuccess2?.(String(id));
          }
          return true;
        }

        //update part
        const body = {email: guestData.email, name: guestData.name, quantity: codeState?.[numId] || 0};
        const res = await updateCodeGroupGuest({groupId, guestId: String(id), body});
        if (res) {
          onSuccess?.();
          onSuccess2?.(String(id));
        }
        return true;
      } catch (e) {
        console.log(e);
        const jsonErr = JSON.parse(JSON.stringify(e));
        openNotifError(jsonErr?.response?.body?.message || getErrorResponse(e));
        onSuccess?.(); //refetch
        refetchGroup?.();
        return false;
      }
    };

  return {updateTarget};
};
