import React from 'react';
import {CodeActionTypes} from '../types/codes';
import {ButtonTypes} from '../ui-kit/Button';

export type TableColumnT = {
  title: string | React.ReactNode;
  dataIndex: string;
  key: string;
  render?: (a: any) => React.ReactNode;
};

export enum GroupDetailTableFields {
  code = 'code',
  quantity = 'quantity',
  email = 'email',
  actions = 'actions',
  guestName = 'guestName',
  search = 'search',
}

export const GroupDetailTableColumnsTitles = {
  quantity: 'Quantity',
  code: 'Promo Codes',
  actions: 'Action',
};

export const ViewCodeMap = {
  [CodeActionTypes.sent]: ButtonTypes.tertiary,
  [CodeActionTypes.applied]: ButtonTypes.quaternary,
};
