import {Select} from 'antd';
import React from 'react';
import styled from 'styled-components';
import {CodeGroupT} from '../../external_usage';

type PropsT = {
  selectedGroup?: CodeGroupT;
  groups?: CodeGroupT[];
  onGroupChange?: (id: string | number) => void;
};

export const GroupPicker: React.FC<PropsT> = ({groups, selectedGroup, onGroupChange}) => {
  const options = groups?.map((el) => ({label: el?.clientName, value: el?.id}));
  return (
    <Wrapper>
      <Title>Selected Group</Title>
      <Select options={options} value={selectedGroup?.id} onChange={onGroupChange} />
    </Wrapper>
  );
};

const Wrapper = styled.div`
  margin-bottom: 12px;
  .ant-select {
    width: 100%;
    .ant-select-selector {
      border-radius: 4px;
      height: 40px;
      padding-top: 4px;
    }
  }
`;

const Title = styled.div`
  font-size: 12px;
  margin-bottom: 4px;
`;
