import React from 'react';
import {WelcomePage} from '../../components/Welcome';
import {useViewer} from '../../hooks/auth';
import {useGetGroups} from '../../hooks/groups';
import {PageLoader} from '../../ui-kit/Loader';

export const Welcome = () => {
  const user = useViewer();
  const {groups, loading} = useGetGroups();
  if (loading) return <PageLoader />;

  const lastGroup = groups?.[0];
  return <WelcomePage user={user} group={lastGroup} />;
};
