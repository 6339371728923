import React, {useEffect} from 'react';
import {useNavigate} from 'react-router-dom';
import {route} from '../../constants/routes';
import {MagicLinkReceived} from '../../components/MagicLink/MagicLinkReceived';
import {useSearchParams} from 'react-router-dom';
import {searchKeys} from '../../types/helpers';
import {useLoginMagicLink} from '../../hooks/magicLink';

export const SendgridMagicLink = () => {
  const [searchParams] = useSearchParams();
  const paramEmail = searchParams.get(searchKeys.email);
  const paramMagicCode = searchParams.get(searchKeys.magicCode);
  const navigate = useNavigate();
  const goToWelcome = () => navigate(route.welcomeScreen.path);
  const {onLogin} = useLoginMagicLink(goToWelcome);

  useEffect(() => {
    if (paramEmail && paramMagicCode) onLogin({email: paramEmail, magicCode: paramMagicCode});
  }, [!!paramEmail]);

  return <MagicLinkReceived />;
};
